import React from 'react';
import { Copyright } from './Footer.style';

const Footer = () => {
  return (
    <>
      <Copyright>
        <p>Copyright &copy; Jeral Doyley 2022 </p>
      </Copyright>
    </>
  );
};

export default Footer;
