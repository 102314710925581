import styled from 'styled-components';

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 999;
  height: 4em;
  background-color: ${(props) => props.navBackground};
`;

export const NavContainer = styled.div`
  width: 80vw;
  margin: auto;
  display: flex;
  align-items: center;
  height: 4em;
  justify-content: space-between;
`;

export const Logo = styled.div``;

export const LogoLink = styled.a`
  text-decoration: none;
  display: flex;

  & > h1 {
    font: 1.5em 'Red Hat Display', sans-serif;
    color: #90fb9b;
  }
`;

export const NavLinks = styled.ul`
  display: flex;
`;

export const NavItem = styled.li`
  list-style: none;
  margin-right: 1.5em;

  & > a {
    text-decoration: none;
    &:hover {
      color: grey;
    }
  }
`;

export const ContactLink = styled.a`
  color: #90fb9b;

  &:hover {
    color: grey;
  }
`;
