import styled from 'styled-components';

export const ProjectBox = styled.div`
  & h1,
  & figcaption {
    text-align: center;
  }

  & figcaption {
    margin-top: 1em;
  }

  & a {
    color: #90fb9b;
    text-decoration: none;
    &:hover {
      color: #30e162;
    }
  }

  & img {
    display: flex;
    width: 25em;
    margin: auto;
  }

  @media (max-width: 1300px) {
    & img {
      width: 20em;
    }
  }
`;
