import styled from 'styled-components';

export const MobileNav = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 4em;
  background-color: ${(props) => props.navBackground};
  z-index: 1;
`;
