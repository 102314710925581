import React from 'react';
import { Circles, HomeSection, Intro, Name } from './Home.style';

const Home = () => {
  return (
    <HomeSection id="home">
      <Intro>
        <p>
          Hello, my name is <Name>Jeral Doyley</Name> I am a junior front-end
          web developer based in Toronto, Ontario
        </p>
      </Intro>
      <Circles>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </Circles>
    </HomeSection>
  );
};

export default Home;
