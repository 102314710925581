import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import env from 'react-dotenv';
import {
  Form,
  FormContainer,
  ShortInput,
  SubmitButton,
  TextBox,
  ContactParagraph,
} from './Contact.style';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_o7p26fp',
        'template_r1yiv34',
        form.current,
        'U7tWX14JjJyAdlvnY'
      )
      .then(
        (result) => {
          alert('Your email has been sent');
        },
        (error) => {
          alert('There was an error sending your email');
        }
      );
    setClear({
      user_name: '',
      user_email: '',
      message: '',
    });
  };

  const [clear, setClear] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  //Changes state to allow the values to change in the form
  const handleChange = (e) => {
    let { name, value } = e.target;

    setClear((prevValue) => {
      return { ...prevValue, [name]: value };
    });
  };

  return (
    <section id="contact">
      <h2 style={{ textAlign: 'center' }}>Contact</h2>

      <FormContainer>
        <ContactParagraph>
          Please feel free to reach out to me if you’d like me to join your
          team. Thank you!
        </ContactParagraph>

        <Form ref={form} onSubmit={sendEmail}>
          <ShortInput
            style={{ marginRight: '1%' }}
            type="text"
            placeholder="Full Name"
            name="user_name"
            value={clear.user_name}
            onChange={handleChange}
          />
          <ShortInput
            type="email"
            placeholder="Email"
            name="user_email"
            value={clear.user_email}
            onChange={handleChange}
          />
          <TextBox
            type="text"
            placeholder="Message"
            name="message"
            value={clear.message}
            onChange={handleChange}
          />
          <div className="g-recaptcha" data-sitekey={env.EMAIL_KEY}></div>
          <SubmitButton type="submit" value="send">
            Send Email
          </SubmitButton>
        </Form>
      </FormContainer>
    </section>
  );
};

export default Contact;
