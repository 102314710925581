import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 5em;
  row-gap: 2em;
  margin: 4em auto 0;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  }
`;
