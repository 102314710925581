import Data from '../data/data.json';
import React from 'react';
import SingleProject from './SingleProject';
import { GridContainer } from './Projects.style';

const Projects = () => {
  return (
    <section id="projects">
      <h2>Projects</h2>
      <GridContainer>
        {Data.map((project) => {
          return (
            <SingleProject
              key={project.id}
              title={project.appname}
              blurb={project.blurb}
              pic={project.pic}
              altText={project.alt}
              github = {project.github}
              url={project.url}
            />
          );
        })}
      </GridContainer>
    </section>
  );
};

export default Projects;
