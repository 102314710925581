import styled, { keyframes } from 'styled-components';

const float = keyframes`
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
`;

export const HomeSection = styled.section`
  height: 100vh;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 80vh;
  }
`;

export const Intro = styled.div`
  position: relative;
  top: 30%;
  left: 10%;
  width: 35vw;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    top: 20%;
    width: 60vw;
  }

  @media screen and (max-width: 767px) {
    top: 20%;
    width: 100%;
    left: 0%;
  }
`;

export const Name = styled.span`
  display: block;
  font: bold 4em 'Montserrat', sans-serif;
  color: #90fb9b;
`;

export const Circles = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    height: 80vh;
  }

  & li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background-color: rgba(40, 233, 23, 0.2);
    animation: ${float} 25s linear infinite;
    bottom: -150px;
  }

  & li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  & li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  & li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  & li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  & li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  & li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  & li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  & li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  & li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  & li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
`;
