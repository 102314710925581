import React, { useState } from 'react';
import { bubble as Menu } from 'react-burger-menu';
import './Sidebar.css';

const Sidebar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Menu
      onOpen={handleOpen}
      onClose={handleClose}
      isOpen={open}
      width={'100%'}
    >
      <a onClick={handleClose} className="menu-item" href="#home">
        Home
      </a>
      <a onClick={handleClose} className="menu-item" href="#about">
        About
      </a>
      <a onClick={handleClose} className="menu-item" href="#skills">
        Skills
      </a>
      <a onClick={handleClose} className="menu-item" href="#projects">
        Projects
      </a>
      <a onClick={handleClose} className="menu-item" href="#contact">
        Contact Me
      </a>
    </Menu>
  );
};

export default Sidebar;
