import styled from 'styled-components';

export const FormContainer = styled.div`
  position: relative;
  margin-top: 2em;
`;

export const ContactParagraph = styled.p`
  display: block;
  text-align: center;
`;

export const Form = styled.form`
  margin-top: 2em;
`;

export const ShortInput = styled.input`
  width: 49.5%;
  height: 3em;
  color: black;
  padding: 1em 1em;
  font-size: 1rem;

  @media screen and (max-width: 767px) {
    width: 100%;

    &:nth-child(2) {
      margin-top: 1em;
    }
  }
`;

export const TextBox = styled.textarea`
  resize: none;
  height: 15em;
  width: 100%;
  margin-top: 1em;
  color: black;
  padding: 1em 1em;
  font-size: 1rem;
`;

export const SubmitButton = styled.button`
  background-color: #90fb9b;
  width: 200px;
  height: 59px;
  margin: 1em auto;
  border-radius: 7px;
  border-style: none;
  font-size: 1.5em;
  color: black;
  position: relative;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &:hover {
    background-color: grey;
    color: #90fb9b;
  }
`;
