import React from 'react';
import {
  AboutContainer,
  AboutLink,
  AboutParagraph,
  Avatar,
  Button,
  ButtonDiv,
} from './About.style';

const About = () => {
  return (
    <section id="about">
      <AboutContainer>
        <AboutParagraph>
          <h2>About Me</h2>
          <p>
            I am a junior front-end web developer with a passion to learn and
            implement new concepts and technologies. I am currently looking to
            join a company in which I can grow and contribute my skills.
          </p>

          <ButtonDiv>
            <AboutLink href="https://www.dropbox.com/s/hpbe0qciqni6stx/Jeral%20Doyley%27s%20Developer%20R%C3%A9sum%C3%A9%202022.pdf?dl=0">
              <Button buttonColor="#30E162">Résumé</Button>
            </AboutLink>
            <AboutLink href="https://github.com/Ryokenis">
              <Button buttonColor="#90fb9b">Github</Button>
            </AboutLink>
          </ButtonDiv>
        </AboutParagraph>
        <Avatar src="../images/avatar.png" alt="Avatar of me" />
      </AboutContainer>
    </section>
  );
};

export default About;
