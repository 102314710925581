import React, { useState } from 'react';
import {
  Nav,
  NavItem,
  NavLinks,
  Logo,
  LogoLink,
  ContactLink,
  NavContainer,
} from './Navbar.style';

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  const changeNavbar = () => {
    if (window.scrollY >= 64) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeNavbar);

  return (
    <Nav navBackground={navbar ? 'black' : 'transparent'}>
      <NavContainer>
        <Logo>
          <LogoLink href="#">
            <h1>&lt; JD/ &gt;</h1>
          </LogoLink>
        </Logo>

        <NavLinks>
          <NavItem>
            <a href="#about">About</a>
          </NavItem>
          <NavItem>
            <a href="#skills">Skills</a>
          </NavItem>
          <NavItem>
            <a href="#projects">Projects</a>
          </NavItem>
          <NavItem>
            <ContactLink href="#contact">Contact Me</ContactLink>
          </NavItem>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
};

export default Navbar;
