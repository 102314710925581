import styled from 'styled-components';

export const Copyright = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4em;

  & p {
    font-size: 0.75em;
  }
`;
