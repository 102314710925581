import React, { useState } from 'react';
import { MobileNav } from './MobileNavbar.style';
import { Logo, LogoLink } from '../navbar/Navbar.style';

const MobileNavbar = () => {
  const [navbar, setNavbar] = useState(false);

  const changeNavbar = () => {
    if (window.scrollY >= 64) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeNavbar);

  return (
    <MobileNav navBackground={navbar ? 'black' : 'transparent'}>
      <Logo style={{ position: 'relative', left: '36px' }}>
        <LogoLink href="#">
          <h1>&lt; JD/ &gt;</h1>
        </LogoLink>
      </Logo>
    </MobileNav>
  );
};

export default MobileNavbar;
