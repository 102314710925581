import React from 'react';
import { ProjectBox } from './SingleProject.style';

const SingleProject = (props) => {
  return (
    <ProjectBox>
      <h1 style={{ color: '#30E162' }}>{props.title}</h1>
      <a href={props.url}>
        <img src={props.pic} alt={props.altText} />
      </a>
      <figcaption>
        {props.blurb} [<a href={props.github}>Github</a>]
      </figcaption>
    </ProjectBox>
  );
};

export default SingleProject;
