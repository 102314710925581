import { createGlobalStyle } from 'styled-components';

export const Globalstyles = createGlobalStyle`
  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  color: #BDBBBB;
  
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #2b2b2b;
  /* background: linear-gradient(117.21deg, #434343 0%, black 100%) fixed; */
}

section {
  height: 90vh;

  @media screen and (max-height: 600px){ 
    min-height: 40em;
  }
}

h2{
  font: bold 2.25em 'Montserrat', san-serif;
  color: #90fb9b;
  padding-top: 2em;

  @media screen and (min-width: 768px) and (max-width: 1063px) {
    font-size: 2.7em;
  }
}

p {
  font-size: 1.5em;
}

@media screen and (max-width: 1023px) {
  section {
    height: fit-content;
  }

  h2 {
      text-align: center;
  }
}

`;
