import styled from 'styled-components';

export const SkillSection = styled.section`
  margin-bottom: 5em;

  @media screen and (max-height: 400px) {
    margin-bottom: 10em;
  }
`;

export const BoxDiv = styled.div`
  display: flex;
  position: relative;
  top: 6em;
  width: 75%;
  margin: auto;

  @media screen and (max-width: 1063px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 87%;
    top: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const SkillBox = styled.div`
  @media screen and (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 400px;
    height: 375px;
    background: ${(props) => props.boxColor};
    top: ${(props) => props.boxTop};
    margin-right: ${(props) => props.boxMargin};
    box-shadow: 0px 0px 15px #ffffff;
    border-radius: 7px;

    -webkit-transition: transform 700ms ease-in-out;
    -moz-transition: transform 700ms ease-in-out;
    -ms-transition: transform 700ms ease-in-out;
    transition: transform 700ms ease-in-out;

    &:hover {
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
`;

export const SkillList = styled.ul`
  & > li {
    list-style: none;
    color: black;
    font-size: 1.5em;
    line-height: 2.5em;
    text-align: center;

    @media screen and (max-width: 767px) {
      color: #bdbbbb;
      padding-left: 10px;
      text-align: left;

      &::marker {
        content: '>';
        color: #90fb9b;
      }
    }
  }
`;
