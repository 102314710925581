import React from 'react';
import { SkillSection, SkillBox, BoxDiv, SkillList } from './Skills.style';

const Skills = () => {
  return (
    <SkillSection id="skills">
      <h2 style={{ textAlign: 'center' }}>Skills</h2>
      <BoxDiv>
        <SkillBox boxColor="#90FB9B" boxMargin="6em" boxTop="5em">
          <SkillList>
            <li>React</li>
            <li>HTML5</li>
            <li>CSS</li>
            <li>Node.js</li>
            <li>MongoDB</li>
          </SkillList>
        </SkillBox>
        <SkillBox boxColor="#30E162" boxMargin="6em">
          <SkillList>
            <li>Express</li>
            <li>Javascript</li>
            <li>JQuery</li>
            <li>Bootstrap</li>
            <li>Github</li>
          </SkillList>
        </SkillBox>
        <SkillBox boxColor="#90FB9B" boxTop="5em">
          <SkillList>
            <li>Heroku</li>
            <li>Figma</li>
            <li>Ruby on Rails</li>
            <li>Sass</li>
            <li>Git</li>
          </SkillList>
        </SkillBox>
      </BoxDiv>

      {/* <div>
        <ul>
          <li>React</li>
          <li>HTML5</li>
          <li>CSS</li>
          <li>Node.js</li>
          <li>MongoDB</li>
          <li>Express</li>
          <li>Javascript</li>
          <li>JQuery</li>
          <li>Bootstrap</li>
          <li>Github</li>
          <li>Heroku</li>
          <li>Figma</li>
          <li>VS Code</li>
          <li>Sass</li>
          <li>Git</li>
        </ul>
      </div> */}

    </SkillSection>
  );
};

export default Skills;
