import React, { useState, useEffect } from 'react';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import { Globalstyles } from './components/Global.style';
import { Container } from './App.style';
import Home from './components/home/Home';
import Projects from './components/projects/Projects';
import Skills from './components/skills/Skills';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Sidebar from './components/sidebar/Sidebar';
import MobileNavbar from './components/mobileNavbar/MobileNavbar';

const App = () => {
  const [sidebar, setSidebar] = useState(false);

  let handleResize = () => {
    if (window.innerWidth < 1023) {
      setSidebar(true);
      console.log('Sidebar should be showing');
    } else {
      setSidebar(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener('resize', handleResize);

  return (
    <div id={'outer-container'}>
      <Globalstyles />
      {sidebar ? (
        <Sidebar
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
        />
      ) : (
        <Navbar />
      )}
      {sidebar ? <MobileNavbar /> : null}
      <Container id={'page-wrap'}>
        <Home />
        <About />
        <Skills />
        <Projects />
        <Contact />
        <Footer />
      </Container>
    </div>
  );
};

export default App;
