import styled from 'styled-components';

export const AboutContainer = styled.div`
  position: relative;
  top: 10%;
  display: flex;

  & p {
    padding-top: 2em;
  }

  @media (max-width: 1023px) {
    display: block;
  }
`;

export const AboutParagraph = styled.div`
  margin-right: 10em;
  width: 60%;

  @media screen and (max-width: 1023px) {
    margin: none;
    width: 100%;
    display: block;
    margin: auto;
  }

  @media screen and (max-width: 767px) {
    & p {
      text-align: center;
    }
  }
`;

export const Avatar = styled.img`
  position: relative;
  display: block;
  max-width: 240px;
  max-height: 240px;
  top: 5em;

  @media (max-width: 1023px) {
    top: 2em;
    left: 17rem;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ButtonDiv = styled.div`
  padding-top: 2em;

  @media screen and (max-width: 767px) {
    width: 12.5rem;
    margin: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1063px) {
    width: 28rem;
    margin: auto;
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.buttonColor};
  width: 200px;
  height: 59px;
  border-radius: 7px;
  border-style: none;
  font-size: 1.5em;
  color: black;

  &:nth-child(1) {
    margin: 0.5em 0.5em;
    @media screen and (max-width: 767px) {
      margin: 0.5em 0;
    }
  }

  &:hover {
    background-color: grey;
    color: #90fb9b;
  }
`;

export const AboutLink = styled.a`
  text-decoration: none;
`;
