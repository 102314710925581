import styled from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  margin: auto;

  @media screen and (max-width: 1023px) {
    width: 95vw;
    margin: auto;
  }
`;
